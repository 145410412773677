import React from "react";
import Resume from "../../resume.json"; // Assuming this is the correct path

// Helper function to build badges using local images
function buildBadges(certificates) {
  let credlyBadges = [];
  let columnSize = 12 / certificates.length; // Adjust column size based on the number of certificates
  let columnClass = "column has-text-centered is-" + columnSize;

  certificates.forEach((cert, index) => {
    credlyBadges.push(
      <div key={index} className={columnClass}>
        <figure className="image is-inline-block">
          {/* Use the local image from the images folder */}
          <img
            src={process.env.PUBLIC_URL + "/images/" + cert.x_imageName}
            alt={cert.name + " by " + cert.issuer}
            style={{ width: "186px", height: "186px" }}  // Force uniform size for all badges
          />
        </figure>
        <p className="title is-6">{cert.name}</p>
        <p className="subtitle is-7">{cert.issuer}</p>
      </div>
    );
  });
  return credlyBadges;
}

function Certifications(props) {
  return (
    <section className="section" id="certifications">
      <div className="container">
        <h1 className="title">Certifications</h1>
        <div className="columns is-centered">
          {buildBadges(Resume.certificates)}
        </div>
      </div>
    </section>
  );
}

export default Certifications;
